import { createRouter, createWebHistory,  createWebHashHistory } from 'vue-router'
import login from '../pages/login/login.vue'

const globalRoutes=[
  {
    path: '/login',
    name: 'login',
    component: login
  },
];

const mainRoutes = {
    path: '/',
    component: () => import('../pages/home/home.vue'),
    redirect: { name: "home" },
    children: [
      {
        path: '/',
        name: 'userList',
        component: () => import('../pages/home/userList.vue')
      },
      {
        path: '/talk',
        name: 'talk',
        component: () => import('../pages/home/talk.vue')
      },
    ]
  }



const router = createRouter({
  history: createWebHashHistory(),
  routes: globalRoutes.concat(mainRoutes), 
})

export default router
