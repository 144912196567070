import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import request from '../src/utils/request'
import JMessage from '@/components/JM/jmessage-sdk-web.2.6.0.min'
import vant from 'vant'
import 'vant/lib/index.css'

const app = createApp(App);

app.config.globalProperties.$request = request;   //全局注册request，其他文件可通过this.$request使用分装好的request实例


app.use(store).use(router).use(vant).mount('#app')


// 极光聊天
const JIMVersion = store.state.JIMVersion;
console.log(JIMVersion);
//极光IM在线聊天配置
var JIM = new JMessage({
  // debug: true
});

//初始化
function JIMInit(auth_payload) {
  // return new Promise((resolve, reject)=> {
    //首次进入app未获取auth_payload
    if (auth_payload == undefined && store.state.im_auth_payload == "") {
      console.log('//首次进入app未获取auth_payload');
      getAuthPayload()
      return
    }
    //缓存中有auth_payload
    if (auth_payload == undefined && store.state.im_auth_payload != '') {
      console.log('//缓存中有auth_payload', auth_payload);
      auth_payload = store.state.im_auth_payload
    }
    auth_payload.flag = 1
  // return new Promise((resolve, reject) => {
    console.log("极光auth_payload:----------------",store.state.im_auth_payload.flag, auth_payload.flag)
    //初始化
    JIM.init(auth_payload).onSuccess(function(data) {
      console.log("-----极光IM初始化完成-----", data)
      //登录
      console.log("-----极光发起登录-----")
      // const username = `${JIMVersion}_25` //测试,替换uid
      const username = `${JIMVersion}_${JSON.parse(sessionStorage.getItem("storeInfo")).id}`
      console.log(username);
      JIM.login({
        'username': username,
        'password': '123456'
      }).onSuccess(data => {
        // resolve(data)
        console.log("-----极光登录成功-----", data)
        let storeName = JSON.parse(sessionStorage.getItem("storeInfo")).name
        // 修改极光昵称
        JIM.updateSelfInfo({
          'nickname' : storeName,
        }).onSuccess(function(data) {
            console.log("修改名字",data);
            //data.code 返回码
            //data.message 描述
        }).onFail(function(data) {
            //同上
            console.log(data);
        });
        store.state.JiGuangInitState = true;
        //获取漫游消息
        getSyncConversation();
      }).onFail(data => {
        if (data.code == 880103) {
          //用户不存在，进行注册
          console.log("-----极光 用户不存在，进行注册-----")
          JIMRegister(username)
        } else {
          console.log(data)
        }
      })
    }).onFail(data => {
      if (data.code == 880106) {
        //签名过期，重新获取签名
        console.log("-----极光签名过期，重新获取签名-----")
        getAuthPayload()
      } else {
        console.log(data)
      }
    });
  // })
  
}

//获取签名
function getAuthPayload() {
    //获取签名
    console.log("-----极光开始获取签名-----")
		request.get("/store/get_jg_signature",{
    }).then(res => {
			store.commit('increment', res.data)
			console.log(res.data);
			JIMInit(res.data)
		}).catch(error => {
			console.error(error);
		})
}


//断线监听
JIM.onDisconnect(function () {
  console.log('断线监听');
  getAuthPayload()
});

//注册
function JIMRegister(username) {
  console.log("-----用户不存在，开始自动注册极光-----")
  JIM.register({
    'username': username,
    'password': '123456',
  }).onSuccess(function (data) {
    if (data.code == 0) {
      JIMInit()
      console.log("注册极光账户成功，重新调起初始化")
    }
  }).onFail(function (data) {
    console.log(data)
  });
}

//获取离线消息列表
function getSyncConversation() {
  //是否已经调过离线缓存消息了，如果调过了，就设个true
	store.state.isGetSyncConversation = true;
  console.log('main---');
  JIM.onSyncConversation(function (data) {
    console.log(data,"main.js/161");
    let sync_conversationNew = [];
    let sync_conversationServe = [];
    data.map((item,i)=>{
      if (item.from_username ==  "gyJgAdmin") {
        sync_conversationServe.push(item.msgs);
        JIM.resetUnreadCount({
          'username': "gyJgAdmin",
          "appkey": "01d0021e31ab6b57ca63a4df"
        });
      }else {
        sync_conversationNew.push(item);
      }
    })
		sessionStorage.setItem('sync_conversation', JSON.stringify(sync_conversationNew));
  });
}


export  {
  JIM,
  JIMInit,
  getSyncConversation
}