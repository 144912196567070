import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      // gystore: `http://testgystore.guanyumall.com`, //测试
      // JIMVersion: "debug_sd",  //新测试
			gystore: 'http://gystore.guanyumall.com', //正式
      JIMVersion: "release",    //新正式
			// 极光
			// JIMAppkey: "b0cb5a6dfc1cf9f6b2a071a4",
			JIMToAppkey: "01d0021e31ab6b57ca63a4df",
			//极光是否初始化完成状态值
			JiGuangInitState: false,
      im_auth_payload: '',
      isGetSyncConversation: '',
    }
  },
  getters: {
  },
  mutations: {
    increment (state,payload) {
      state.im_auth_payload = payload 
    },
    setIsGetSyncConversation(state,e) {
      state.isGetSyncConversation = e
    },
  },
  actions: {
  },
  modules: {
  }
})
