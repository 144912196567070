import axios from 'axios';
import store from '../store/index.js';
import router from '../router/index.js'
var baseURL = store.state.gystore;
//创建axios实例，简写代码
const request = axios.create({
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    baseURL,  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
    // 如果请求花费了超过 `timeout` 的时间，请求将被中断
    timeout: 30000,
    //请求头，可以不写
    // headers: {'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjUyNzc0NjUsImV4cCI6MTY2Nzg2OTQ2NSwidHlwZSI6MSwiaWQiOjE4ODczfQ.h5aYitU0WUmqCBaEUQWzYMqsSybdQTfeMxKWn0cR8aY'}
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    var access_token = JSON.parse(sessionStorage.getItem('access_token'))
	// console.log(access_token,'111111111111111111111');
    if (access_token == null || access_token == '') {
        access_token = store.state.access_token
    }
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.headers['Authorization'] = `Bearer ${access_token}`;  
    // config.headers['contentType'] = 'application/x-www-form-urlencoded';
    // config.headers['Accept'] = 'application/vnd..v1+json';
    // config.headers['token'] = user.token;  // 设置请求头
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        // console.log('response',response);
        let res = response.data;
        // 如果是返回的文件
        //Blob(Binary long Object)是二进制长对象的意思,Blob通常用于存储大文件,典型的Blob内容是一张图片或者一个声音文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        res.status = response.status
        return res;
    },
    error => {
        console.log(error.response) // for debug
        let err = {
          status: error.response.status,
          data: error.response.data
        }
        return Promise.reject(err) 
    }
)
export default request
