<template>
	<div class="page">
			<img class="logo" src="../../assets/logo.png">
			<van-cell-group inset class="input">
				<div class="mobile">
					<van-field v-model="loginAccount.mobile" type="digit" placeholder="请输入账号"/>
					<van-icon v-if="loginAccount.mobile" class="close" name="close" @click="clearVal(1)"/>
				</div>
				<div class="mobile">
					<van-field v-model="loginAccount.password" type="password" placeholder="请输入密码"/>
					<van-icon v-if="loginAccount.password" class="close" name="close" @click="clearVal(2)"/>
				</div>
			</van-cell-group>
			<button class="but" @click="confirm()">登录</button>
			<img class="bg" src="../../assets/bg.png">
			<div class="tip" v-if="showInfo">{{showInfo}}</div>
	</div>
</template>


<script>
import md5 from 'js-md5';
import { showNotify } from 'vant';
import { showDialog } from 'vant';
export default {
	data() {
		//这里存放数据
		return {
			loginAccount: {
				mobile: '',
				password: '',
        version: 1
			},
			loginAccount1: {
				mobile: '',
				password: '',
        version: 1
			},
			showInfo: ''
		};
	},
	//方法集合
	methods: {
		clearVal(e) {
			console.log(e);
			// return
			if (e == 1) {
				this.loginAccount.mobile= '';
			}else {
				this.loginAccount.password = '';
			}
		},
		keyDown(e){
			//如果是回车则执行登录方法
      if(e.keyCode == 13){
				window.removeEventListener('keydown',this.keyDown,false);
				this.confirm();
      }
    },
		confirm() {
			let that = this;
			console.log(that.loginMobile,that.loginAccount,that.showM);
			let data;
			if(that.loginAccount.mobile == '') {
				this.showInfo = '请填写正确的账号';
				setTimeout(()=> {
					this.showInfo = '';
					window.addEventListener('keydown',this.keyDown);
				},2000)
				return
			}else if(that.loginAccount.password == '') {
				this.showInfo = '请填写正确的密码';
				setTimeout(()=> {
					this.showInfo = '';
					window.addEventListener('keydown',this.keyDown);
				},2000)
				return
			}
			// return
			//进行深拷贝
			//防止出现用户名输入错误后点击登录导致密码也被转换为加密后的数据
			data = JSON.parse(JSON.stringify(this.loginAccount));
			data.password = md5(data.password);

			that.$request.post('/store/login',data).then(res => {
        console.log(res);
				if (res.data.is_main == 0 && res.data.power.length == 0) {
					this.showInfo = '请联系管理员添加权限！！！';
					setTimeout(()=> {
						this.showInfo = '';
						window.addEventListener('keydown',this.keyDown);
					},2000)
					return
				}
				sessionStorage.setItem('access_token', JSON.stringify(res.data.access_token));
        sessionStorage.setItem('refresh_token', JSON.stringify(res.data.refresh_token));
				sessionStorage.setItem('login', JSON.stringify(res.data));
				window.removeEventListener('keydown',that.keyDown,false);  // 销毁回车事件
				this.$store.state.JiGuangInitState = true;
				this.getStoreInfo();
      }).catch(error => {
        console.error(error);
				this.showInfo = error.data.msg;
				that.loginAccount.password = '';
				setTimeout(()=> {
					this.showInfo = '';
					window.addEventListener('keydown',this.keyDown);
				},2000)
      })
		},
		getStoreInfo() {
			this.$request.get('/store/store_info').then(res => {
				sessionStorage.setItem('storeInfo', JSON.stringify(res.data));
				this.$router.push({name: 'userList'});
			})
		},
	},
	//监控data中的数据变化
	watch: {
		
	},
	//这里存放数据
	components: {

	},
	//计算属性 类似于data概念
	computed: {
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		sessionStorage.removeItem('headImage');
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		//绑定事件
    window.addEventListener('keydown',this.keyDown);
	},
	destroyed(){
		// 销毁回车事件
    window.removeEventListener('keydown',this.keyDown,false);
  },
};
</script>

<style scoped lang='scss'>
.page {
	width: 375px;
	height: 812px;
	background-color: #fff;
	.logo {
		width: 219px;
		position: relative;
		top: 90px;
	}
}
.bg {
	width: 375px;
	position: absolute;
	bottom: 0;
	left: 0;
}
.input {
	position: relative;
	top: 198px;
}
.but{
	width: 80%;
	padding: 10px 0;
	position: relative;
	top: 300px;
	background-color: #F0F0F0;
	color: #999999;
	border-radius: 25px;
	border: #f0f0f0;
}
.van-cell {
	width: 85%;
	display: inline-block;
	border-bottom: #F4F4F4 solid 1px;
}
.close {
	position: relative;
	top: -10px;
	color: #999999;
}
.tip {
	height: 40px;
	width: 240px;
	position: absolute;
	left: calc(50% - 120px);
	top: 350px;
	background: rgba(219, 133, 3, 0.8);
	line-height: 40px;
	border-radius: 10px;
	color: #fff;
}
</style>
